import { Box, ButtonGroup, Card, CardHeader, CardContent, CardActions, createStyles, FormHelperText, FormLabel, InputBase, InputLabel, Radio, Theme, Typography, withStyles } from "@material-ui/core";

export const PANFieldContainer = withStyles(() => ({
    root: {
        position: 'relative',
        flex: 1,
        marginBottom: 8,
        display: 'flex',
        flexFlow: 'column',
    }
}))(Box);

export const PANLabelStyle = withStyles((theme: Theme) => ({
    root: {
        fontWeight: 700,
        color: "#333333"
    }
}))(InputLabel);

export const PANHelpText = withStyles(() => ({
    root: {
        color: "#D13C3C",
    }
}))(FormHelperText)

export const PANMutedText = withStyles(() => ({
    root: {
        color: "#707070",
        lineHeight: 1.2,
    }
}))(FormHelperText)

export const PANInputStyle = withStyles((theme: Theme) =>
createStyles({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        background: '#FFFFFF',
        border: '1px solid #DADBDB',
        boxSizing: 'border-box',
        borderRadius: 4,
        padding: '7px 8px',
        fontSize: 16,
        height: 'auto',
    },
  }),
)(InputBase);

export const PANMainContainer = withStyles(() => ({
  root: {
    background: "#F4F5F5",
    position: "relative",
    height: "100%",
  },
}))(Box);

export const PANChipContainer = withStyles(() => ({
    root: {

    }
}))(Box)

export const SelectButtonContainer = withStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    }
}))(ButtonGroup)

export const SelectLabel = withStyles(() => ({
    root: {
        fontWeight: 700,
        fontSize: 12,
        marginBottom: 10,
    }
}))(Typography)

export const PANFormLabel = withStyles(() => ({
    root: {
        fontSize: 12,
        fontWeight: 700,
        marginBottom: '9px',
        color: '#333333',
        display: "flex",
        alignItems: "center",
    }
}))(FormLabel)

export const StyledRadio = withStyles(() => ({
    root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
}))(Radio)

export const RadioLabelContainer = withStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row'
    }
}))(Box)

export const RadioTitleStyle = withStyles(() => ({
    root: {
        fontSize: 12,
        color: '#333333',
    },
}))(Typography)

export const RadioMutedStyle = withStyles(() => ({
    root: {
        fontSize: 12,
        color: '#707070',
        marginLeft: 2,
    },
}))(Typography)

export const MultiListHeaderContainer = withStyles(() => ({
    root: {
        display: 'flex',

    }
}))(Box)

export const PANCardContent = withStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: "16px 16px 8px 19px",
        '& img': {
            padding: "0px 20px 0 0",
        },
        color: "#333333",
    }
}))(CardContent);

export const PANCardHeader = withStyles(() => ({
    root: {
        display: 'flex',
        borderBottom: '1px solid #DADBDB',
        padding: "8px 8px 8px 16px",
        color: "#333333",
    }
}))(CardHeader);

export const PANCardActions = withStyles(() => ({
    root: {
        display: 'flex',
        borderTop: '1px solid #DADBDB',
        padding: "8px 8px 8px 16px",
        minHeight: 40,
        backgroundColor: '#F4F5F5',
    }
}))(CardActions);

export const PANCard = withStyles(() => ({
    root: {
        border: '1px solid #DADBDB',
        width: 360,
    }
}))(Card);
