import { makeStyles } from "@material-ui/core";
import { Input } from "@panwds/react-ui";
import { PANFieldContainer, PANHelpText, PANMutedText } from "../FormElements/styles";
import {PANWDSLabel} from "./PANWDSLabel";

const useStyles = makeStyles((theme) => ({
    error: {
        border: '1px solid #D13C3C',
        borderRadius: 4,
    },
    disabledInput: {
        '& input': {
            '--tw-text-opacity': 1,
            color: "rgb(184 184 184 / var(--tw-text-opacity))",
        }
    },
    readOnly: {
        border: '1px solid #DADBDB'
    }
}));

export const PANWDSInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => {
    const classes = useStyles();
    const existerror = !!(touched && error);
    const { title, required, note, placeholder, dataMetrics, disabled, autoFocus, maxLength, readOnly } = props;

    return <PANFieldContainer>
        {title && <PANWDSLabel disabled={disabled} required={required} title={title}/>}
        <Input
            placeholder={placeholder}
            addClassName={disabled && classes.disabledInput || readOnly && classes.readOnly}
            value={inputProps.value}
            danger={existerror}
            dataMetrics={dataMetrics}
            onChange={inputProps.onChange}
            onBlur={inputProps.onBlur}
            onFocus={inputProps.onFocus}
            disabled={disabled}
            autoFocus={autoFocus}
            type={inputProps.type || "text"}
            maxLength={maxLength}
            readOnly={readOnly}
        />
        {note && (<PANMutedText disabled={disabled}>{note}</PANMutedText>)}
        {existerror && (<PANHelpText>{error}</PANHelpText>)}
    </PANFieldContainer>;
};
