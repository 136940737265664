export const darkTheme = {
    palette: {
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        type: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
    },
    typography: {
        fontFamily: 'Lato !important',
    },
    overrides: {
        RaAppBar: {
            toolbar: {
                '& button': {
                    '&:not(:nth-child(1))': {
                        display: 'none'
                    }
                }
            }
        },
        RaSkipToContentButton: {
            skipToContentButton: {
                display: 'none !important'
            }
        },
        RaLayout: {
            appFrame: {
                margin: '0 !important',
            },
            content: {
                margin: '0 !important',
                padding: '0 !important',
                backgroundColor: '#F4F5F5',
            }
        },
        RaList: {
            root: {
                padding: "16px"
            }
        },
        MuiAppBar: {
            colorSecondary: {
                border: 'none',
                color: '#ffffffb3',
                backgroundColor: '#616161e6',
            },
        },
        RaCardContentInner: {
            root: {
                padding: 0,
                margin: 0
            }
        },
        MuiChip: {
            colorSecondary: {
                backgroundColor: '#EAEBEB',
                color: '#333333'
            }
        },
        MuiCardContent: {
            root: {
                padding: 0,
                margin: 0
            }
        },
        MuiButton: {
            root: {
                borderRadius: '4px !important',
                minWidth: 80,
                height: 32,
            },
            contained: {
                backgroundColor: '#fff',
                color: '#4f3cc9',
                boxShadow: 'none',
            },
            containedPrimary: {
                backgroundColor: '#006FCC !important',
                color: 'white !important',
                '&:hover': {
                    backgroundColor: '#005499 !important'
                }
            },
            containedSecondary: {
                backgroundColor: '#FFF !important',
                color: '#006FCC !important',
                border: '1px solid #DADBDB !important',
                '&:hover': {
                    backgroundColor: '#f1f1f1 !important'
                }
            },
            containedSizeLarge: {
                padding: "6px 16px !important",
                fontSize: "14px !important",
                height: "38px !important"
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit'
                },
            },
        },
        MuiFormHelperText: {
            root: {
                'white-space': 'pre-line'
            }
        }
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
};

export const lightTheme = {
    '*': {
        boxShadow: 'none !important',
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 650,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    palette: {
        primary: {
            main: '#19222E',
            contrastText: '#edf0f4'
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },

        type: 'light' as 'light',
    },
    typography: {
        fontFamily: 'Lato !important',
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        MuiMenuItem: {
            root: {
                fontSize: 12,
            }
        },
        MuiTableCell: {
            root: {
                color: '#333333'
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 12,
                color: '#333333',
            }
        },
        RaSkipToContentButton: {
            skipToContentButton: {
                display: 'none !important'
            }
        },
        RaAutocompleteSuggestionList : {
            suggestionsContainer: {
                boxShadow: '0px 2px 2px rgb(0 0 0 / 24%)',
                backgroundColor: 'white',
                borderRadius: '4px',
            }
        },
        PrivateTabIndicator: {
            colorPrimary: {
                backgroundColor: "#006FCC"
            }
        },
        RaLayout:{
            appFrame: {
                margin: '0 !important',
            },
            content: {
                margin: '0 !important',
                padding: '0 !important',
                backgroundColor: '#F4F5F5'
            },
            root: {
              minWidth: '100%'
            }
        },
        RaList: {
            root: {
                margin: "16px",
                background: "#FFFFFF",
                border: "1px solid #DADBDB",
                boxSizing: "border-box",
                borderRadius: 4,
            }
        },
        RaCreate: {
            root: {
                height: '100%'
            },
            main: {
                height: '100%'
            },
            noActions: {
                margin: '0 !important',
            }
        },
        RaEdit: {
            root: {
                height: '100%'
            },
            main: {
                height: '100%'
            },
            noActions: {
                margin: '0 !important',
            }
        },
        RaAppBar: {
            toolbar: {
                '& button': {
                    '&:not(:nth-child(1))': {
                        display: 'none'
                    }
                }
            }
        },
        RaMenuItemLink: {
            root: {
                paddingTop: '12px !important',
                paddingBottom: '12px !important',
                fontSize: '12px !important',
                fontWeight: 400,
                color: '#B8C1CC !important',
                '&:hover': {
                    background: '#37424f !important',
                }
            },
            active: {
                color: '#fff !important',
                fontWeight: 700,
                borderLeft: '3px solid #38A5FF !important',
                '&:hover': {
                    background: '#37424f !important',
                }
            }
        },
        RaToolbar:{
            mobileToolbar: {
                margin: '16px 0',
                padding: '0 16px 0 0 !important',
            }
        },
        MuiTabs: {
            root: {
                backgroundColor: "#FFF",
                minHeight: '35px',
            },
            indicator: {
                backgroundColor: '#006FCC'
            },
            flexContainerVertical: {
                '& .MuiTab-root': {
                    fontWeight: 400,
                    // minWidth: '160px',
                }
            },
            flexContainer: {
                '& .MuiTab-root': {
                    fontWeight: 700,
                    // minWidth: '100px !important',
                }
            },
        },
        MuiTab: {
            root: {
                fontSize: '12px',
                minHeight: '35px',
            },
        },
        MuiPaper: {
            root: {
                backgroundClip: 'padding-box',
                // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
                border: '1px solid #DADBDB',
            },
            rounded: {
                borderRadius: '4px',
            },
            elevation1: {
                boxShadow: 'unset',
            },
            elevation2: {
                boxShadow: 'unset',
            },
        },
        MuiTypography: {
            h5: {
                fontSize: '16px',
            }
        },
        MuiCardHeader: {
            root: {
                padding: '0px 0px 0px 16px !important',
                minHeight: '41px',
            },
            title: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems : 'center',
            }
        },
        MuiGrid: {
            container: {
                '&$spacing-xs-3': {
                  width: `auto !important`,
                  '& $item.noLeftPadding': {
                    paddingLeft: 0,
                  },
                    '& $item.noPadding': {
                      padding: 0,
                    },
                    '& $item.bottomPadding': {
                        padding: '0 0 12px 0',
                    },
                  margin: 0,
                },
            }
        },
        MuiIconButton: {
            colorSecondary: {
                color: '#006FCC !important',
                border: '1px solid #DADBDB !important',

            },
            sizeSmall: {
                height: '38px',
                width: '38px'
            }
        },
        MuiTableBody: {
            root: {
                width: '100%',
            }
        },
        MuiButton: {
            root: {
                borderRadius: '4px !important',
                minWidth: 60,
                height: 32,
            },
            contained: {
                backgroundColor: '#fff',
                color: '#4f3cc9',
                boxShadow: 'none',
                "&:hover": {
                    boxShadow: 'none'
                },
                "&:disabled": {
                    color: "rgba(0, 0, 0, 0.26) !important",
                    boxShadow: "none",
                    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
                },
            },
            containedPrimary: {
                backgroundColor: '#006FCC !important',
                color: 'white !important',
                '&:hover': {
                    backgroundColor: '#005499 !important'
                }
            },
            containedSecondary: {
                backgroundColor: '#FFF !important',
                color: '#006FCC !important',
                border: '1px solid #DADBDB !important',
                '&:hover': {
                    backgroundColor: '#f1f1f1 !important'
                }
            },
            containedSizeLarge: {
                padding: "6px 16px !important",
                fontSize: "14px !important",
                height: "32px !important"
            },
            label: {
                fontSize: 12,
                display: 'flex',
                justifyContent: 'space-between',
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit'
                },
            }
        },
        MuiAppBar: {
            colorSecondary: {
                border: 'none',
                color: '#edf0f4',
                backgroundColor: '#19222E',
            },
        },
        RaAutocompleteArrayInput: {
            inputRootFilled: {
                background: '#FFFFFF',
                border: '1px solid #DADBDB',
                boxSizing: 'border-box',
                borderRadius: 4,
                padding: '0px 8px',
                fontSize: 16,
                minHeight: '41px',
            },
            chipContainerFilled: {
                margin: 0,
            }
        },
        RaCardContentInner: {
            root: {
                padding: '0px !important',
                margin: 0
            }
        },
        RaSidebar: {
            root: {
                height: 'auto'
            },
            fixed: {
                height: '100%'
            }
        },
        MuiChip: {
            colorSecondary: {
                backgroundColor: '#EAEBEB',
                color: '#333333'
            },
            deleteIconColorSecondary: {
                color: '#333333',
                '&:hover': {
                    color: '#333333',

                }
            },
            deletableColorSecondary: {
                backgroundColor: '#EAEBEB',
                color: '#333333',
                '&:focus': {
                    backgroundColor: '#EAEBEB',
                }
            },
        },
        MuiCardContent: {
            root: {
                padding: 0,
                margin: 0,
                fontSize: '12px',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFormControl: {
            marginDense: {
                marginTop: 0,
                marginBottom: 0,
            }
        },
        MuiInputLabel: {
            root: {
                transform: 'translate(0) !important',
                fontSize: 12,
                fontWeight: 700,
                color: 'rgba(0, 0, 0, 0.54);'
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'transparent',
                '&:hover, &:focus, &:active': {
                    backgroundColor: 'transparent',

                },
                'label + &': {
                    marginTop: '24px',
                },
            },
            adornedStart: {
                paddingLeft: 0,
            },
            underline: {
                '&::before, &::after': {
                    content: 'none'
                }
            }
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
};
