import {Subtitle} from "@panwds/react-ui";
import {useTranslate} from "../../../../customHooks";
import {FormControl, Grid} from "@material-ui/core";
import {Field, FormSpy} from "react-final-form";
import {PANWDSCheckboxField, PANWDSInput, PANWDSRadioField} from "../../../../components/PANWDSElements";
import {Row} from "../../../../components/FormElements";
import * as React from "react";
import {composeValidators, isAlphaNumeric, isRequired, maxLength} from "../../../../utils/validate";
import {OnChange} from "react-final-form-listeners";
import {useState} from "react";

const Index = () => {
    const translate = useTranslate();

    const [isOwnPublicIPs, setIsOwnPublicIPs] = useState<boolean>(false);

    return (
        <>
            <Row>
                <Grid item sm={12} className="noPadding" style={{paddingBottom: "1rem"}}>
                    <FormControl>
                        <Field
                            name="EgressNatEnable"
                            // @ts-ignore
                            component={PANWDSCheckboxField}
                            defaultValue={false}
                            label={"Enable Egress NAT"}
                            type="checkbox"
                            dataMetrics={"cloudngfw-firewall-create-egressNatEnabled"}
                            muted={"Enabling Egress NAT allows for the use of public IPs from AWS Services or your own IPs. In the Endpoint Management section, you can manage Egress NAT for each endpoint."}
                        />
                    </FormControl>
                </Grid>
            </Row>
            {/*TODO: uncomment this for phase 2*/}
            {/*<FormSpy subscription={{values: true}}>*/}
            {/*    {props => {*/}
            {/*        let EgressNatEnable = props?.values?.EgressNatEnable;*/}
            {/*        if (EgressNatEnable) {*/}
            {/*            return (*/}
            {/*                <>*/}
            {/*                    <Row>*/}
            {/*                        <Grid item xs={12} sm={6} className="noPadding">*/}
            {/*                            <FormControl fullWidth>*/}
            {/*                                <Field*/}
            {/*                                    name="PublicIPs"*/}
            {/*                                    row*/}
            {/*                                    // @ts-ignore*/}
            {/*                                    component={PANWDSRadioField}*/}
            {/*                                    initValue={EgressNatEnable ? 'AwsServiceIPs' : 'OwnPublicIPs'}*/}
            {/*                                    options={[*/}
            {/*                                        {label: "AWS Service IPs", value: "AwsServiceIPs"},*/}
            {/*                                        {label: "Bring Your Public IPs ", value: "OwnPublicIPs"}*/}
            {/*                                    ]}*/}
            {/*                                    title={"Public IPs"}*/}
            {/*                                    required*/}
            {/*                                    validate={isRequired}*/}
            {/*                                    defaultValue={EgressNatEnable ? 'AwsServiceIPs' : 'OwnPublicIPs'}*/}
            {/*                                    dataMetrics={"cloudngfw-firewall-create-egress-nat-public-ips"}*/}
            {/*                                />*/}
            {/*                                <OnChange name="PublicIPs">*/}
            {/*                                    {(selected: any) => setIsOwnPublicIPs(selected === 'OwnPublicIPs')}*/}
            {/*                                </OnChange>*/}
            {/*                            </FormControl>*/}
            {/*                        </Grid>*/}
            {/*                    </Row>*/}
            {/*                    {isOwnPublicIPs && <Row>*/}
            {/*                        <Grid item xs={12} sm={6} className="noPadding">*/}
            {/*                            <FormControl fullWidth>*/}
            {/*                                <Field*/}
            {/*                                    name="OwnPublicIPs"*/}
            {/*                                    placeholder={"Please enter a pool of public IP addresses (e.g., 192.168.1.2 to 192.168.1.100)"}*/}
            {/*                                    // @ts-ignore*/}
            {/*                                    component={PANWDSInput}*/}
            {/*                                    required*/}
            {/*                                    dataMetrics="cloudngfw-firewall-create-egress-nat-public-own-ips"*/}
            {/*                                />*/}
            {/*                            </FormControl>*/}
            {/*                        </Grid>*/}
            {/*                    </Row>}*/}
            {/*                </>*/}
            {/*            )*/}
            {/*        } else {*/}
            {/*            return <></>;*/}
            {/*        }*/}
            {/*    }}*/}
            {/*</FormSpy>*/}
        </>
    );
};

export default Index;
