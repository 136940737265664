import cx from "classnames";
import {isEmpty} from "lodash";
import CircularLoader from "../../../../components/CircularLoader/CircularLoader";
import {PANTile} from "../../../../components";
import {PANWDSTable} from "../../../../components/PANWDSElements";
import {useTranslate} from "../../../../customHooks";

const Index = ({record, classes}: {record: any, classes: any}) => {
    const translate = useTranslate();

    const endpointsColumns = [
        {
            accessor: 'EndpointId',
            Header: translate(`resources.firewalls.fields.EndpointId`),
            columnSize: 2,
            render: ({ row }: any) => <span>{row?.original?.EndpointId}</span>,
        },
        {
            accessor: 'AccountId',
            Header: translate(`resources.firewalls.fields.AccountId`),
            columnSize: 2,
            render: ({ row }: any) => <span>{row?.original?.AccountId}</span>,
        },
        {
            accessor: 'VpcId',
            Header: translate(`resources.firewalls.fields.VpcId`),
            columnSize: 2,
            render: ({ row }: any) => <span>{row?.original?.VpcId}</span>,
        },
        {
            accessor: 'Status',
            Header: translate(`resources.firewalls.fields.EndpointStatus`),
            columnSize: 3,
            render: ({ row }: any) => {
                if (row?.original?.Status === "REJECTED") {
                    return (<span>{row?.original?.Status} - {row?.original?.RejectedReason}</span>);
                }
                return (<span>{row?.original?.Status}</span>);
            },
        },
        {
            name: 'SubnetId',
            Header: translate(`resources.firewalls.fields.SubnetId`),
            columnSize: 2,
            render: ({ row }: any) => <span>{row?.original?.SubnetId}</span>,
        }
    ];

    return (
        <div className={cx(classes.pantileContainer, isEmpty(record) ? classes.loadingBlur : "")}>
            <CircularLoader loading={isEmpty(record)} />
            <PANTile
                title={translate(`resources.firewalls.fields.Details`)}
                size={12}
                titlePadding="12px 16px"
                padding="17px"
                divider
            >
                <div className={classes.tileContent}>
                    <div>{translate('resources.firewalls.fields.EndpointServiceName')}:</div>
                    <div>{record?.Firewall?.EndpointServiceName || 'N/A'}</div>
                </div>
            </PANTile>
            <PANWDSTable
                title={translate(`resources.firewalls.fields.Endpoints`)}
                columns={endpointsColumns}
                idProperty={''}
                gridData={record?.Status?.Attachments || []}
                multiSelect={false}
                singleSelect={false}
                offsetTableHeight={600}
                overflowTable={true}
                showTileTitle
                tableContainerStyle={{}}
                dataTestId="cloudngfw-firewall-endpoints-table"
            />
        </div>
    );
};

export default Index;
