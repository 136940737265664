import { Body, Italic, Link } from "@panwds/react-ui";
import { FormControl, Grid } from "@material-ui/core";
import { Field, FormSpy, useForm } from "react-final-form";
import {
    PANWDSInput,
    PANWDSCheckboxField,
} from "../../../../components/PANWDSElements";
import * as React from "react";
import { useTranslate } from "../../../../customHooks";
import CircularLoader from "../../../../components/CircularLoader/CircularLoader";
import { Row } from "../../../../components/FormElements";
import {
    composeValidators,
    isPort,
    isRequired,
    minLength,
} from "../../../../utils/validate";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { ExclamationCircleIcon, ExclamationTriangleIcon } from "@panwds/icons";
import { OnChange } from "react-final-form-listeners";

const Index = ({
    record,
    setSidePanel,
}: {
    record: any;
    setSidePanel: any;
}) => {
    const translate = useTranslate();
    const form = useForm();

    const CustomIncludeExcludeNetworkColumns = React.useMemo(
        () => [
            {
                accessor: "Name",
                Header: translate(
                    `resources.firewalls.fields.CustomIncludeExcludeNetworkName`
                ),
                columnSize: 2,
                Cell: ({ row }: any) => <span>{row?.original?.Name}</span>,
            },
            {
                accessor: "Enabled",
                Header: translate(
                    `resources.firewalls.fields.CustomIncludeExcludeNetworkEnabled`
                ),
                columnSize: 2,
                Cell: ({ row }: any) => (
                    <span>
                        {row?.original?.Enabled === true ? "Yes" : "No"}
                    </span>
                ),
            },
            {
                accessor: "NetworkAddress",
                Header: translate(
                    `resources.firewalls.fields.CustomIncludeExcludeNetworkNetworkAddress`
                ),
                columnSize: 2,
                Cell: ({ row }: any) => (
                    <span>{row?.original?.NetworkAddress}</span>
                ),
            },
            {
                accessor: "DiscoveryInclude",
                Header: translate(
                    `resources.firewalls.fields.CustomIncludeExcludeNetworkDiscoveryInclude`
                ),
                columnSize: 2,
                Cell: ({ row }: any) => (
                    <span>
                        {row?.original?.DiscoveryInclude === true
                            ? "Yes"
                            : "No"}
                    </span>
                ),
            },
        ],
        []
    );

    const dropDownActionsArray: any[] = [];
    dropDownActionsArray.push({
        title: translate(`resources.firewalls.fields.AddIncludeExcludeRule`),
        actionsMap: [],
        dataMetrics: "cloudngfw-firewall-edit-add-CustomIncludeExcludeNetwork",
        handleButtonAction: () => setSidePanel("add"),
        type: "button",
        appearance: "primary",
    });

    const displayUserIDStatus = (status: string) => {
        if (/EndpointPending|DisablePending/.test(status)) {
            return (
                <>
                    <span>( </span>
                    <ExclamationTriangleIcon
                        size="xs"
                        className="tw-text-orange-600 dark:tw-text-dark-bg-orange"
                    />
                    <Italic> Pending )</Italic>
                </>
            );
        } else if (/EndpointFail|CommitFail/.test(status)) {
            return (
                <>
                    <span>( </span>
                    <ExclamationCircleIcon
                        size="xs"
                        className="tw-text-red-600 dark:tw-text-dark-bg-red"
                    />
                    <Italic> Failed )</Italic>
                </>
            );
        } else if (status === "Disabled") {
            return (
                <>
                    <span>( {status} )</span>
                </>
            );
        } else {
            return <></>;
        }
    };

    const handleEnablePrivateAccessUpdate = (selected: any, previous: any) => {
        if (selected === false) {
            form.change("Firewall.PrivateAccess.EnablePrivateAccess", false);
            form.change("Firewall.UserID.Enabled", false);
        } else if (selected === true) {
            form.change("Firewall.PrivateAccess.EnablePrivateAccess", true);
            form.change("Firewall.UserID.Enabled", true);
        }
    };

    const handleUserIdUpdate = (selected: any, previous: any) => {
        if (selected === false) {
            form.change("Firewall.PrivateAccess.EnablePrivateAccess", false);
        } else if (selected === true) {
            form.change("Firewall.PrivateAccess.EnablePrivateAccess", true);
        }
    };

    return (
        <>
            {isEmpty(record) && (
                <div style={{ minHeight: "100px" }}>
                    <CircularLoader loading={isEmpty(record)} />
                </div>
            )}

            {!isEmpty(record) && (
                <>
                    <FormSpy subscription={{ values: true }}>
                        {({ values, ...rest }) => (
                            <>
                                <Row>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <Field
                                                name="Firewall.PrivateAccess.EnablePrivateAccess"
                                                // @ts-ignore
                                                component={PANWDSCheckboxField}
                                                //initialValue={values?.Firewall?.UserID?.Enabled === true}
                                                label={translate(
                                                    `resources.firewalls.fields.EnableAWSNetworkLoadBalanacer`
                                                )}
                                                type="checkbox"
                                                //disabled={!permissions?.updateResource}
                                                dataMetrics={
                                                    "cloudngfw-firewall-edit-enable-aws-network-load-balancer"
                                                }
                                                muted={
                                                    <FormattedMessage
                                                        id={
                                                            "resources.firewalls.fields.EnableAWSNetworkLoadBalancerHelpText"
                                                        }
                                                        values={{
                                                            document: (
                                                                <Link
                                                                    dataMetrics="cloudngfw-aws-network-load-balancer-help"
                                                                    external
                                                                    href="https://docs.aws.amazon.com/elasticloadbalancing/latest/network/introduction.html"
                                                                >
                                                                    {translate(
                                                                        `generic.Document`
                                                                    )}
                                                                </Link>
                                                            ),
                                                        }}
                                                    />
                                                }
                                                disabled={/EndpointPending|DisablePending/.test(
                                                    values?.Firewall?.UserID
                                                        ?.UserIDStatus
                                                )}
                                            />
                                            <OnChange
                                                name={
                                                    "Firewall.PrivateAccess.EnablePrivateAccess"
                                                }
                                            >
                                                {(
                                                    selected: any,
                                                    previous: any
                                                ) =>
                                                    handleEnablePrivateAccessUpdate(
                                                        selected,
                                                        previous
                                                    )
                                                }
                                            </OnChange>
                                        </FormControl>
                                    </Grid>
                                </Row>
                                {values?.Firewall?.PrivateAccess
                                    ?.EnablePrivateAccess && (
                                    <>
                                        <Row>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        name="Firewall.PrivateAccess.ResourceID"
                                                        required
                                                        validate={composeValidators(
                                                            isRequired,
                                                            minLength(20)
                                                        )}
                                                        // @ts-ignore
                                                        component={PANWDSInput}
                                                        title={translate(
                                                            `resources.firewalls.fields.AmazonResourceARN`
                                                        )}
                                                        dataMetrics={
                                                            "cloudngfw-firewall-edit-amazon-resource-arn"
                                                        }
                                                        note={
                                                            <FormattedMessage
                                                                id={
                                                                    "resources.firewalls.fields.AmazonResourceARNHelpText"
                                                                }
                                                                values={{
                                                                    document: (
                                                                        <Link
                                                                            dataMetrics="cloudngfw-aws-resource-arn-help"
                                                                            external
                                                                            href="https://docs.aws.amazon.com/vpc/latest/privatelink/privatelink-share-your-services.html"
                                                                        >
                                                                            {translate(
                                                                                `generic.Document`
                                                                            )}
                                                                        </Link>
                                                                    ),
                                                                }}
                                                            />
                                                        }
                                                        //disabled={values?.Firewall?.UserID?.UserIDStatus.matchAll === ["EndpointPending", "DisablePending"]}
                                                        disabled={/EndpointPending|DisablePending|Enabled/.test(
                                                            values?.Firewall
                                                                ?.UserID
                                                                ?.UserIDStatus
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Row>
                                        <Row>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        name="Firewall.UserID.Enabled"
                                                        // @ts-ignore
                                                        component={
                                                            PANWDSCheckboxField
                                                        }
                                                        checked={
                                                            values?.Firewall
                                                                ?.UserID
                                                                ?.Enabled
                                                        }
                                                        label={
                                                            <FormattedMessage
                                                                id={
                                                                    "resources.firewalls.fields.EnableUserID"
                                                                }
                                                                values={{
                                                                    status: (
                                                                        <Body
                                                                            size="xs"
                                                                            as={
                                                                                "span"
                                                                            }
                                                                        >
                                                                            {displayUserIDStatus(
                                                                                values
                                                                                    ?.Firewall
                                                                                    ?.UserID
                                                                                    ?.UserIDStatus
                                                                            )}
                                                                        </Body>
                                                                    ),
                                                                }}
                                                            />
                                                        }
                                                        type="checkbox"
                                                        //disabled={!permissions?.updateResource}
                                                        dataMetrics={
                                                            "cloudngfw-firewall-edit-enable-user-id"
                                                        }
                                                        muted={translate(
                                                            `resources.firewalls.fields.EnableUserIDHelpText`
                                                        )}
                                                        disabled={/EndpointPending|DisablePending/.test(
                                                            values?.Firewall
                                                                ?.UserID
                                                                ?.UserIDStatus
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Row>
                                        {values?.Firewall?.UserID?.Enabled && (
                                            <>
                                                <Row>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                name="Firewall.UserID.Port"
                                                                required
                                                                validate={composeValidators(
                                                                    isRequired,
                                                                    isPort
                                                                )}
                                                                defaultValue={
                                                                    5007
                                                                }
                                                                // @ts-ignore
                                                                component={
                                                                    PANWDSInput
                                                                }
                                                                title={translate(
                                                                    `resources.firewalls.fields.Port`
                                                                )}
                                                                dataMetrics={
                                                                    "cloudngfw-firewall-edit-amazon-resource-arn"
                                                                }
                                                                note={translate(
                                                                    `resources.firewalls.fields.PortHelpText`
                                                                )}
                                                                disabled={/EndpointPending|DisablePending|Enabled/.test(
                                                                    values
                                                                        ?.Firewall
                                                                        ?.UserID
                                                                        ?.UserIDStatus
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Row>
                                                <Row>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                name="Firewall.UserID.CollectorName"
                                                                // @ts-ignore
                                                                component={
                                                                    PANWDSInput
                                                                }
                                                                title={translate(
                                                                    `resources.firewalls.fields.CollectorName`
                                                                )}
                                                                dataMetrics={
                                                                    "cloudngfw-firewall-edit-collector-name"
                                                                }
                                                                note={
                                                                    <FormattedMessage
                                                                        id={
                                                                            "resources.firewalls.fields.CollectorNameHelpText"
                                                                        }
                                                                        values={{
                                                                            document:
                                                                                (
                                                                                    <Link
                                                                                        dataMetrics="cloudngfw-collector-name-help"
                                                                                        external
                                                                                        href="https://docs.paloaltonetworks.com/panorama/9-1/panorama-admin/manage-firewalls/redistribute-user-id-information-to-managed-firewalls"
                                                                                    >
                                                                                        {translate(
                                                                                            `generic.Document`
                                                                                        )}
                                                                                    </Link>
                                                                                ),
                                                                        }}
                                                                    />
                                                                }
                                                                disabled={/EndpointPending|DisablePending|Enabled/.test(
                                                                    values
                                                                        ?.Firewall
                                                                        ?.UserID
                                                                        ?.UserIDStatus
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Row>
                                                <Row>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                name="Firewall.UserID.SecretKeyARN"
                                                                // @ts-ignore
                                                                // @ts-ignore
                                                                component={
                                                                    PANWDSInput
                                                                }
                                                                title={translate(
                                                                    `resources.firewalls.fields.AWSSecretKeyARN`
                                                                )}
                                                                dataMetrics={
                                                                    "cloudngfw-firewall-edit-aws-secret-key-arn"
                                                                }
                                                                note={
                                                                    <FormattedMessage
                                                                        id={
                                                                            "resources.firewalls.fields.AWSSecretKeyARNHelpText"
                                                                        }
                                                                        values={{
                                                                            document:
                                                                                (
                                                                                    <Link
                                                                                        dataMetrics="cloudngfw-aws-resource-arn-help"
                                                                                        external
                                                                                        href="https://docs.aws.amazon.com/secretsmanager/latest/userguide/whats-in-a-secret.html"
                                                                                    >
                                                                                        {translate(
                                                                                            `generic.Document`
                                                                                        )}
                                                                                    </Link>
                                                                                ),
                                                                        }}
                                                                    />
                                                                }
                                                                disabled={/EndpointPending|DisablePending|Enabled/.test(
                                                                    values
                                                                        ?.Firewall
                                                                        ?.UserID
                                                                        ?.UserIDStatus
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Row>
                                            </>
                                        )}
                                    </>
                                )}
                                <OnChange name={"Firewall.UserID.Enabled"}>
                                    {(selected: any, previous: any) =>
                                        handleUserIdUpdate(selected, previous)
                                    }
                                </OnChange>
                            </>
                        )}
                    </FormSpy>
                </>
            )}
        </>
    );
};

export default Index;
